import React, { useEffect, useState, useCallback, useRef } from "react";
import { Container } from "aurelia-framework";
import moment from "moment-timezone";
import { Loader } from "rsuite";
import { Client } from "../../api/client";
import { FlashService } from "../../flash/flash-service";
import confirm from "../../dialog/confirm";
import { OrderContextProvider } from "./helper/order-context";
import { getConfigOptions } from "./helper/config-model";
import { DEFAULT_DAY_START_TIME, getStartingTimeDict } from "./helper/utility";
import {
  getOfferSettingUrl,
  getOrderItemUrl,
  getDeleteOrderItemUrl,
  getAddOrderItemUrl,
  getOrderItemOptionalUrl,
  getOrderItemMoveUrl,
} from "./helper/url";

import "./offer.less";
import { GMapContextProvider } from "./helper/gmap-context";
import { OfferSearch } from "./views/offer-search";
import { EventAggregator } from "aurelia-event-aggregator";
import { StandardActions } from "../../action/standard-actions";

const getPayload = (event) => {
  let fromDate;
  if (event.extendedProps.isAccommodation) {
    fromDate = moment(event.start).startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const toDate = moment(event.end)
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    return {
      startDate: fromDate,
      endDate: toDate,
    };
  }

  fromDate = moment.utc(event.start).format("YYYY-MM-DD HH:mm:ss");
  return {
    startDate: fromDate,
  };
};

const isAccommodation = (item) =>
  item?.provider?.includes("tourism-accommodation");

const Section = ({ children, className }) => {
  const ref = useRef();
  useEffect(() => {
    let offset;
    const setHeight = () => {
      const { top } = ref.current.getBoundingClientRect();
      const topOffset = top + window.scrollY;
      if (offset !== topOffset) {
        ref.current.style.setProperty("--top", `${topOffset}px`);
        offset = topOffset;
      }
    };
    const interval = setInterval(() => setHeight(), 300);
    return () => {
      clearTimeout(interval);
    };
  }, []);
  return (
    <section ref={ref} className={className}>
      {children}
    </section>
  );
};

export const Offer = ({ order }) => {
  const client = Container.instance.get(Client);
  const flash = Container.instance.get(FlashService);
  const ea = Container.instance.get(EventAggregator);

  const [offerModel, setOfferModel] = useState({
    firstDate: null,
    lastDate: "",
    participantsForm: 0,
    participantsOrder: order.b2bParticipants,
    optionDate: "",
    offerCancellationDate: "",
    offerDoubleRooms: 0,
    offerSingleRooms: 0,
    roomsReserved: 0,
    duration: 1,
    offerCancellationTime: 1,
    overwriteOfferCancellationDate: false,
    overwriteOptionDate: false,
  });
  const [orderItems, setOrderItems] = useState([]);
  const [requestCounter, setRequestCounter] = useState(0);
  const [selectedDate, setSelectedDate] = useState();
  const [dayStartTimes, setDayStartTime] = useState(
    getStartingTimeDict(order?.startingTimes ?? [])
  );
  const [offerStartTime, setOfferStartTime] = useState(null);
  const [offerEndTime, setOfferEndTime] = useState(null);

  const showErrorMessage = useCallback(
    (message, timeout = 20000) =>
      flash.flash("error", message, null, {
        timeOut: timeout,
      }),
    [flash]
  );

  const runRequest = useCallback(
    async (request, errMessage) => {
      setRequestCounter((counter) => counter + 1);
      let res, error;
      try {
        res = await request();
        error = false;
      } catch (error) {
        console.error(error);
        showErrorMessage(
          error?.data?.localizedMessage ||
            error?.data?.message ||
            error?.message ||
            errMessage
        );
        res = null;
        error = true;
      } finally {
        setRequestCounter((counter) => (counter > 0 ? counter - 1 : 0));
      }
      return [res, error];
    },
    [showErrorMessage]
  );

  const fetchItems = async () => {
    try {
      const url = getOrderItemUrl(order.id);
      const response = await client.get(url);

      setOrderItems(response.items);
    } catch (error) {
      showErrorMessage(
        error?.data?.localizedMessage ?? "Items could not be fetched"
      );
    }
  };

  const onDateChange = useCallback((date) => {
    const firstDate = moment(date);
    setSelectedDate(firstDate);
    setOfferModel((previousValue) => ({
      ...previousValue,
      firstDate,
    }));
  }, []);

  const selectNextAccommodationFreeDay = useCallback(
    (newlyAddedAccommodationTime) => {
      let dateToCheck = moment(newlyAddedAccommodationTime[0]).startOf("day");
      const accommodations = orderItems.filter(isAccommodation).map((i) => {
        const startDate = moment(i.fromDate);
        const endDate = i?.toDate
          ? moment(i.toDate)
          : moment(startDate).add(1, "day");
        return [startDate, endDate];
      });
      accommodations.push(newlyAddedAccommodationTime);
      const hasAccommodation = (date) =>
        accommodations.some(([startDate, endDate]) =>
          date.isBetween(startDate, endDate, "day", "[)")
        );
      while (hasAccommodation(dateToCheck)) {
        dateToCheck = dateToCheck.add("day", 1);
        if (offerEndTime.isSameOrAfter(dateToCheck, "day")) {
          dateToCheck = offerStartTime.clone();
          break;
        }
      }
      onDateChange(dateToCheck);
    },
    [selectedDate, onDateChange, orderItems, offerStartTime, offerEndTime]
  );

  const onDeleteEvent = useCallback(async (id) => {
    confirm("Event löschen", "Möchten Sie dieses Event löschen?").then(
      async () => {
        const remove = async () => {
          const url = getDeleteOrderItemUrl(id);
          await client.remove(url);
          await fetchItems();
          ea.publish("sio_form_post_submit", {
            config: { modelId: "order/order" },
          });
        };
        await runRequest(remove, "Event delete error");
      },
      () => {
        /* prevent unhandled rejection message */
      }
    );
  }, []);

  const updateEventItem = useCallback(
    async (data) => {
      const payload = {
        ...data,
      };
      const url = getAddOrderItemUrl(order.id);
      const update = async () => {
        const create = async () => await client.patch(url, payload);
        if (isAccommodation(payload)) {
          const lastDay = moment(offerEndTime);
          const accStart = moment(payload.fromDate).startOf("day");
          const accEnd = moment(payload.toDate).subtract(1, "day").endOf("day");
          const isStartAfter = accStart.isAfter(lastDay, "day");
          const isEndAfter = accEnd.isAfter(lastDay, "day");
          if (isStartAfter || isEndAfter) {
            showErrorMessage(
              "Can't create accommodation with start/end after the last offer day"
            );
            return;
          }
          await create();
          const isOnLastDay = lastDay.isSame(accEnd, "day");
          isOnLastDay
            ? onDateChange(offerStartTime)
            : selectNextAccommodationFreeDay([accStart, accEnd]);
        } else {
          await create();
        }
        await fetchItems();
        ea.publish("sio_form_post_submit", {
          config: { modelId: "order/order" },
        });
      };
      await runRequest(update, "Event update error");
    },
    [selectNextAccommodationFreeDay, offerEndTime, offerStartTime]
  );

  const setConfigOptions = () => {
    const isPeriodSet = order.offerStartDate && order.offerEndDate;
    if (!isPeriodSet) {
      showErrorMessage(
        "Bitte setzen Sie zuerst den Angebotszeitraum, um eine Reise zu konfigurieren"
      );
    }
    const model = getConfigOptions(order, orderItems, offerModel);
    setOfferStartTime(model.firstDate);
    setOfferEndTime(model.lastDate);
    setOfferModel(model);
    console.info("offer model", model.firstDate);
    setSelectedDate(model.firstDate);
  };

  useEffect(() => {
    setConfigOptions();
    fetchItems();
  }, [order.id]);
  const onOptionalChange = async (isOptional, id) => {
    const payload = {
      optional: isOptional,
    };
    const url = getOrderItemOptionalUrl(id);
    const update = async () => {
      await client.put(url, payload);
      await fetchItems();
    };
    await runRequest(update, "Optional change error");
  };

  const onEventDrag = async (event) => {
    const payload = getPayload(event);
    const url = getOrderItemMoveUrl(event.id);
    const update = async () => {
      await client.patch(url, payload);
      await fetchItems();
    };
    await runRequest(update, "Event drag error");
  };

  const updateDateStartTime = async (date, time) => {
    const payload = {
      startingTimes: Object.entries({
        ...dayStartTimes,
        [date]: time,
      })
        .map(([date, time]) => ({ date, time }))
        .filter(({ time }) => time !== DEFAULT_DAY_START_TIME),
    };
    const url = `tourism-order/modify-starting-time/${order.id}`;
    const update = async () => await client.put(url, payload);
    const [res] = await runRequest(update, "Set start time for date error");
    setDayStartTime(getStartingTimeDict(res?.data?.startingTimes ?? []));
  };

  const addArticleOnDateClick = useCallback((config, context) => {
    const actions = Container.instance.get(StandardActions);
    setRequestCounter((r) => r + 1);
    actions
      .getAction(config, context)
      .action()
      .then(
        () => setRequestCounter((counter) => (counter > 0 ? counter - 1 : 0)),
        () => setRequestCounter((counter) => (counter > 0 ? counter - 1 : 0))
      );
  }, []);

  return (
    <GMapContextProvider>
      <Section className="offer-configurator">
        {requestCounter > 0 && <Loader className="offer-loader" />}
        <OrderContextProvider
          value={{
            dayStartTimes,
            updateDateStartTime,
            onDateChange,
            onDeleteEvent,
            updateEventItem,
            onOptionalChange,
            onEventDrag,
            orderItems,
            order,
            participantsOrder: offerModel.participantsOrder,
            requestCounter,
            showErrorMessage,
            client,
            flash,
            selectedDate,
            setSelectedDate,
            addArticleOnDateClick,
            offerStartTime,
            offerEndTime,
          }}
        >
          <OfferSearch
            items={orderItems}
            firstDate={offerModel.firstDate}
            lastDate={offerModel.lastDate}
            participants={offerModel.participantsOrder}
          />
        </OrderContextProvider>
      </Section>
    </GMapContextProvider>
  );
};

export default Offer;
